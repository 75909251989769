import { AppUtils } from './utils';

export namespace TimeUtils {

	/**
	 * Convert minutes to HH:MM string format.
	 * @param totalMinutes Minutes.
	 */
	export function toHoursAndMinutes(totalMinutes: number): string {
		const hours = AppUtils.zeroPad(Math.floor(totalMinutes / 60), 2);
		const minutes = AppUtils.zeroPad(totalMinutes % 60, 2);

		return `${hours}:${minutes}`;
	}

	/**
	 * Convert seconds to MM:SS string format.
	 * @param totalSeconds Seconds.
	 */
	export function toMinutesAndSeconds(totalSeconds: number): string {
		const minutes = AppUtils.zeroPad(Math.floor(totalSeconds / 60), 2);
		const seconds = AppUtils.zeroPad(totalSeconds % 60, 2);

		return `${minutes}:${seconds}`;
	}

	/**
	 * Transform hours, minutes and seconds to milliseconds.
	 * @param data Hours, minutes, and seconds.
	 */
	export function toMilliseconds(data: ToMillisecondsData): number {
		const { hours = 0, minutes = 0, seconds = 0 } = data;
		return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
	}
}

type ToMillisecondsData = Readonly<{

	/** Hours. */
	hours?: number;

	/** Minutes. */
	minutes?: number;

	/** Seconds. */
	seconds?: number;
}>;
